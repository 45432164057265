.Sticky-base {
  width: 100%;
}

.EBINavBar--stuck-top {
  background-color: #0086b4;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 8;
  box-shadow: 0 0 10px 0 $dark;
}

.EBINavBar--extra-items-menu {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  margin-left: -20px;
  position: absolute;
  z-index: 9;

  .nav-item {
    border: none !important;
  }
}

