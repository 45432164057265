// Callouts.
.callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: $border-radius;
    border-left-color: #343a40;

    table {
        margin: 0;
    }
}

.callout-primary {
    border-left-color: $primary;
}

.callout-secondary {
    border-left-color: $secondary;
}

.callout-success {
    border-left-color: $success;
}

.callout-danger {
    border-left-color: $danger;
}

.callout-warning {
    border-left-color: $warning;
}

.callout-info {
    border-left-color: $info;
}

.callout-dark {
    border-left-color: $dark;
}

.callout-light {
    border-left-color: $light;
}


// Block text with ellipsis.
.text-block {
  hyphens: auto;
  line-height: 1.2em;
  margin-right: -1em;
  //max-height: 3.6em;
  overflow: hidden;
  overflow-wrap: break-word;
  padding-right: 1em;
  position: relative;
  word-break: break-word;
  word-wrap: break-word;
}


// Extra widths.
.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-35 {
  width: 35% !important;
}

.w-90 {
  width: 90% !important;
}


// Rounded corners.
.rounded-top-lg {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.rounded-lg {
  border-radius: $border-radius;
}

.rounded-all-but-top-left-lg {
  border-radius: $border-radius;
  border-top-left-radius: 0;
}

.rounded-all-but-top-right-lg {
  border-radius: $border-radius;
  border-top-right-radius: 0;
}


// Border widths.
.border-2 {
  border-width:2px !important;
}


// Card header grey.
.bg-cardgrey {
  background-color: rgba(0, 0, 0, 0.03);
}


// More colors.
.text-white {
  color: #fff !important;
}