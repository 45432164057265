.EBINav-dropdown {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9;
  margin-left: 0;

  li {
    border: none !important;
  }

  .nav-item {
    border: none !important;
  }
}
