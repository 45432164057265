.footer-section {
  padding: 0 1rem;
  margin-top: 10rem;
  margin-left: auto !important;
  margin-right: auto !important;
}

.global-footer .row {
  display: block;
  margin: 0 auto !important;
}
