.logo {
  display: flex;
  justify-content: center;
  margin-top: 100px;

  img {
    max-height: 61px;
    max-width: 61px;
    width: auto;
    height: auto;
  }

  h1 {
    margin: 0 0 0 .5rem;
    line-height: 1.5;
  }
}

.logo-text {
  color: inherit;
  margin-left: 1rem;
}

.logo-subtitle {
  color: inherit;
  font-size: 1rem;
  font-weight: 300;
  text-align: right;
  margin: -1rem 1.5rem 0 0;
  font-style: italic;
}
